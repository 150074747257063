import React from 'react';
import ReactDynamicImport from 'react-dynamic-import';
import Auth from '@components/auth';
import { detectMob } from '@src/Helper';

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/Advertisement/Advertisement')
    )
  } else {
    return (
      import('@components/Advertisement/Advertisement')
    )
  }
}

const Advertisement = ReactDynamicImport({ loader })


const AdvertisementPage = ({ location }) => <Advertisement location={location} />

export default Auth(AdvertisementPage);
